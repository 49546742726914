<template>
    <div>
        <div class="BlackBar">
            <div class="C1">
                <p>¡POR TIEMPO LIMITADO!</p>
            </div>
            <div class="C2">
                <p>ENVÍO GRATUITO A TODO <span>HERMOSILLO</span></p>
            </div>
        </div>
        <div class="BlackBarPhone">
            <div class="C1PH">
                <p><span class="GoldText">¡POR TIEMPO LIMITADO!</span> ENVÍO GRATUITO A TODO <span class="WhiteText">HERMOSILLO</span></p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'AnuncioBar'
}
</script>

<style scoped>

.BlackBar{
    display: flex;
    width: 100%;
    height: 4.895833333333333VW;
    background-color: black;
    justify-content: center;
    padding-top: 0.5vw;
}
.C1{
    width: 8vw;
    height: 3.5vw;
    margin-right: 1vw;
}
    .C1 p{
        font-size: 1.3020833333333335VW;
        color: #968220;
        font-family: 'Rajdhani';
        text-align: right;
    }
.C2{
    height: 3.5vw;
}
    .C2 p{
        font-size: 2.864583333333333VW;
        color: #B70D1D;
        font-family: 'Rajdhani';
    }
    .C2 span{
        font-size: 2.864583333333333VW;
        color: white;
    }

.BlackBarPhone{
    display: none;
}

@media (max-width: 768px){
    .BlackBar{
        display: none;
    }

    .BlackBarPhone{
        display: block;
        width: 100%;
        height: 14.975845410628018VW;
        background-color: black;
        padding-top: 2.5vw;
        text-align: -webkit-center;
    }
    .C1PH{
        width: 79.95169082125604VW;
        height: 9.280193236714976VW;
    }
    .C1PH p{
        color: #B70D1D;
        font-size: 3.3816425120772946VW;
        font-weight: 700;
        text-align: center;
        font-family: 'Rajdhani';
    }
    .GoldText{
        color: #968220;
    }
    .WhiteText{
        color: white;
    }
}

</style>
