<template>
  <transition name="modal-fade">
  <div class="modal2-backdrop" @click="close">
    <div class="modal2" @click.stop="">
      <header class="modal2-header">
        <button
          type="button"
          class="btn-close"
          @click="close"
        >
          x
        </button>
      </header>

      <section class="modal2-body">
        <slot name="body">
          <div class="talla-body-cont">
          <h1 class="titulo-tallas">Guía de tallas</h1>
          <h3 class="subt-tallas">Encuentra tu talla ideal</h3>
          <div class="genre-tallas">
            <h3 class="genre-name act" id="uno" @click="actGenre('ADULTO') & act(1)">ADULTOS</h3>
            <h3 class="genre-name" id="due" @click="actGenre('NIÑO') & act(2)">NIÑOS</h3>
            <h3 class="genre-name" id="tre" @click="actGenre('DAMA') & act(3)">DAMAS</h3>
          </div>
          <div class="tallas-cont">
            <div id="adult-cont" class="genre-t-cont"> 
              <div class="tallas-cont">
                <div class="talla-name act-road" id="q1" @click="actSizeA(1) & actRoad(1)">XS</div>
                <div class="talla-name" id="q2" @click="actSizeA(2) & actRoad(2)">S</div>
                <div class="talla-name" id="q3" @click="actSizeA(3) & actRoad(3)">M</div>
                <div class="talla-name" id="q4" @click="actSizeA(4) & actRoad(4)">L</div>
                <div class="talla-name" id="q5" @click="actSizeA(5) & actRoad(5)">XL</div>
                <div class="talla-name" id="q6" @click="actSizeA(6) & actRoad(6)">XXL</div>
              </div>

                <div class="camiseta-talla-cont adult-ninio">
                    <div class="tallas-dim-cont">
                      <div class="dim uno">
                        <h5 class="cms largo" id="uno-q">62cms</h5>
                        <h5 class="cms busto" id="dos-q">48cms</h5>
                        <h5 class="cms hombro" id="cuatro-q">20cms</h5>
                      </div>
                    </div>
                </div>

            </div>
            <div id="ninio-cont" class="genre-t-cont">
              <div class="tallas-cont">
                <div class="talla-name act-road2" id="r1" @click="actSizeN(1) & actRoad2(1)">4</div>
                <div class="talla-name" id="r2" @click="actSizeN(2) & actRoad2(2)">6</div>
                <div class="talla-name" id="r3" @click="actSizeN(3) & actRoad2(3)">8</div>
                <div class="talla-name" id="r4" @click="actSizeN(4) & actRoad2(4)">10</div>
                <div class="talla-name" id="r5" @click="actSizeN(5) & actRoad2(5)">12</div>
              </div>

                <div class="camiseta-talla-cont adult-ninio">
                      <div class="dim uno">
                        <h5 class="cms largo" id="uno-r">45cms</h5>
                        <h5 class="cms busto" id="dos-r">32cms</h5>
                        <h5 class="cms hombro" id="cuatro-r">13cms</h5>
                      </div>
                </div>
              </div>
            <div id="dama-cont" class="genre-t-cont">
              <div class="tallas-cont">
                <div class="talla-name act-road3" id="s1" @click="actSizeD(1) & actRoad3(1)">XS</div>
                <div class="talla-name" id="s2" @click="actSizeD(2) & actRoad3(2)">S</div>
                <div class="talla-name" id="s3" @click="actSizeD(3) & actRoad3(3)">M</div>
                <div class="talla-name" id="s4" @click="actSizeD(4) & actRoad3(4)">L</div>
                <div class="talla-name" id="s5" @click="actSizeD(5) & actRoad3(5)">XL</div>
              </div>

                <div class="camiseta-talla-cont dama">
                    <div class="tallas-dim-cont">
                      <div class="dim ">
                        <h5 class="cms largo" id="uno-s">57cms</h5>
                        <h5 class="cms busto" id="dos-s">41cms</h5>
                        <h5 class="cms cintura" id="tres-s">39cms</h5>
                        <h5 class="cms hombro" id="cuatro-s">13cms</h5>
                      </div>
                    </div>
                </div>
              </div>
          </div></div>
        </slot>
       </section>

  <!--    <footer class="modal2-footer">
        <slot name="footer">
        </slot>
      </footer>-->
    </div>
  </div>
  </transition>
</template>


<script>
  export default {
    name: 'Modal',
    methods: {
      close() {
        this.$emit('close');
      },



      actGenre(genre){
        if(genre == 'ADULTO'){
          document.getElementById('adult-cont').style.display = 'flex'
          document.getElementById('ninio-cont').style.display = 'none'
          document.getElementById('dama-cont').style.display = 'none'
        }else if(genre == 'NIÑO'){
          document.getElementById('adult-cont').style.display = 'none'
          document.getElementById('ninio-cont').style.display = 'flex'
          document.getElementById('dama-cont').style.display = 'none'
        }else if(genre == 'DAMA'){
          document.getElementById('adult-cont').style.display = 'none'
          document.getElementById('ninio-cont').style.display = 'none'
          document.getElementById('dama-cont').style.display = 'flex'
        }
      },


      actSizeA(size){
        if(size == 1){
          document.getElementById('uno-q').innerHTML =  '62cms'
          document.getElementById('dos-q').innerHTML = '48cms'
          document.getElementById('cuatro-q').innerHTML = '20cms'
        }else if(size == 2){
          document.getElementById('uno-q').innerHTML = '69cms'
          document.getElementById('dos-q').innerHTML = '50cms'
          document.getElementById('cuatro-q').innerHTML = '23cms'
        }else if(size == 3){
          document.getElementById('uno-q').innerHTML = '71cms'
          document.getElementById('dos-q').innerHTML = '51cms'
          document.getElementById('cuatro-q').innerHTML = '23cms'
        }else if(size == 4){
          document.getElementById('uno-q').innerHTML = '24cms'
          document.getElementById('dos-q').innerHTML = '53cms'
          document.getElementById('cuatro-q').innerHTML = '25cms'
        }else if(size == 5){
          document.getElementById('uno-q').innerHTML = '78cms'
          document.getElementById('dos-q').innerHTML = '57cms'
          document.getElementById('cuatro-q').innerHTML = '26cms'
        }else if(size == 6){
          document.getElementById('uno-q').innerHTML = '78cms'
          document.getElementById('dos-q').innerHTML = '60cms'
          document.getElementById('cuatro-q').innerHTML = '28cms'
        }
      },

      actSizeN(size){
        if(size == 1){
          document.getElementById('uno-r').innerHTML =  '45cms'
          document.getElementById('dos-r').innerHTML = '32cms'
          document.getElementById('cuatro-r').innerHTML = '13cms'
        }else if(size == 2){
          document.getElementById('uno-r').innerHTML = '49cms'
          document.getElementById('dos-r').innerHTML = '35cms'
          document.getElementById('cuatro-r').innerHTML = '15cms'
        }else if(size == 3){
          document.getElementById('uno-r').innerHTML = '53cms'
          document.getElementById('dos-r').innerHTML = '36cms'
          document.getElementById('cuatro-r').innerHTML = '14cms'
        }else if(size == 4){
          document.getElementById('uno-r').innerHTML = '56cms'
          document.getElementById('dos-r').innerHTML = '37cms'
          document.getElementById('cuatro-r').innerHTML = '16cms'
        }else if(size == 5){
          document.getElementById('uno-r').innerHTML = '58cms'
          document.getElementById('dos-r').innerHTML = '41cms'
          document.getElementById('cuatro-r').innerHTML = '18cms'
        }
      },

      act(num){
        if(num == 1){
          document.getElementById('uno').classList.add('act')
          document.getElementById('due').classList.remove('act')
          document.getElementById('tre').classList.remove('act')}
        else if(num == 2){
          document.getElementById('due').classList.add('act')
          document.getElementById('uno').classList.remove('act')
          document.getElementById('tre').classList.remove('act')}
        else if(num == 3){
          document.getElementById('tre').classList.add('act')
          document.getElementById('uno').classList.remove('act')
          document.getElementById('due').classList.remove('act')}
      },

      actRoad(num){
        if(num == 1){
          document.getElementById('q1').classList.add('act-road')
          document.getElementById('q2').classList.remove('act-road')
          document.getElementById('q3').classList.remove('act-road')
          document.getElementById('q4').classList.remove('act-road')
          document.getElementById('q5').classList.remove('act-road')
          document.getElementById('q6').classList.remove('act-road')}
        else if(num == 2){
          document.getElementById('q1').classList.remove('act-road')
          document.getElementById('q2').classList.add('act-road')
          document.getElementById('q3').classList.remove('act-road')
          document.getElementById('q4').classList.remove('act-road')
          document.getElementById('q5').classList.remove('act-road')
          document.getElementById('q6').classList.remove('act-road')}
        else if(num == 3){
          document.getElementById('q1').classList.remove('act-road')
          document.getElementById('q2').classList.remove('act-road')
          document.getElementById('q3').classList.add('act-road')
          document.getElementById('q4').classList.remove('act-road')
          document.getElementById('q5').classList.remove('act-road')
          document.getElementById('q6').classList.remove('act-road')}
        else if(num == 4){
          document.getElementById('q1').classList.remove('act-road')
          document.getElementById('q2').classList.remove('act-road')
          document.getElementById('q3').classList.remove('act-road')
          document.getElementById('q4').classList.add('act-road')
          document.getElementById('q5').classList.remove('act-road')
          document.getElementById('q6').classList.remove('act-road')}
        else if(num == 5){
          document.getElementById('q1').classList.remove('act-road')
          document.getElementById('q2').classList.remove('act-road')
          document.getElementById('q3').classList.remove('act-road')
          document.getElementById('q4').classList.remove('act-road')
          document.getElementById('q5').classList.add('act-road')
          document.getElementById('q6').classList.remove('act-road')}
        else if(num == 6){
          document.getElementById('q1').classList.remove('act-road')
          document.getElementById('q2').classList.remove('act-road')
          document.getElementById('q3').classList.remove('act-road')
          document.getElementById('q4').classList.remove('act-road')
          document.getElementById('q5').classList.remove('act-road')
          document.getElementById('q6').classList.add('act-road')}
      },

      actRoad2(num){
        if(num == 1){
          document.getElementById('r1').classList.add('act-road2')
          document.getElementById('r2').classList.remove('act-road2')
          document.getElementById('r3').classList.remove('act-road2')
          document.getElementById('r4').classList.remove('act-road2')
          document.getElementById('r5').classList.remove('act-road2')}
        else if(num == 2){
          document.getElementById('r1').classList.remove('act-road2')
          document.getElementById('r2').classList.add('act-road2')
          document.getElementById('r3').classList.remove('act-road2')
          document.getElementById('r4').classList.remove('act-road2')
          document.getElementById('r5').classList.remove('act-road2')}
        else if(num == 3){
          document.getElementById('r1').classList.remove('act-road2')
          document.getElementById('r2').classList.remove('act-road2')
          document.getElementById('r3').classList.add('act-road2')
          document.getElementById('r4').classList.remove('act-road2')
          document.getElementById('r5').classList.remove('act-road2')}
        else if(num == 4){
          document.getElementById('r1').classList.remove('act-road2')
          document.getElementById('r2').classList.remove('act-road2')
          document.getElementById('r3').classList.remove('act-road2')
          document.getElementById('r4').classList.add('act-road2')
          document.getElementById('r5').classList.remove('act-road2')}
        else if(num == 5){
          document.getElementById('r1').classList.remove('act-road2')
          document.getElementById('r2').classList.remove('act-road2')
          document.getElementById('r3').classList.remove('act-road2')
          document.getElementById('r4').classList.remove('act-road2')
          document.getElementById('r5').classList.add('act-road2')}
      },

      actRoad3(num){
        if(num == 1){
          document.getElementById('s1').classList.add('act-road3')
          document.getElementById('s2').classList.remove('act-road3')
          document.getElementById('s3').classList.remove('act-road3')
          document.getElementById('s4').classList.remove('act-road3')
          document.getElementById('s5').classList.remove('act-road3')}
        else if(num == 2){
          document.getElementById('s1').classList.remove('act-road3')
          document.getElementById('s2').classList.add('act-road3')
          document.getElementById('s3').classList.remove('act-road3')
          document.getElementById('s4').classList.remove('act-road3')
          document.getElementById('s5').classList.remove('act-road3')}
        else if(num == 3){
          document.getElementById('s1').classList.remove('act-road3')
          document.getElementById('s2').classList.remove('act-road3')
          document.getElementById('s3').classList.add('act-road3')
          document.getElementById('s4').classList.remove('act-road3')
          document.getElementById('s5').classList.remove('act-road3')}
        else if(num == 4){
          document.getElementById('s1').classList.remove('act-road3')
          document.getElementById('s2').classList.remove('act-road3')
          document.getElementById('s3').classList.remove('act-road3')
          document.getElementById('s4').classList.add('act-road3')
          document.getElementById('s5').classList.remove('act-road3')}
        else if(num == 5){
          document.getElementById('s1').classList.remove('act-road3')
          document.getElementById('s2').classList.remove('act-road3')
          document.getElementById('s3').classList.remove('act-road3')
          document.getElementById('s4').classList.remove('act-road3')
          document.getElementById('s5').classList.add('act-road3')}
      },


      actSizeD(size){
        if(size == 1){
          document.getElementById('uno-s').innerHTML =  '57cms'
          document.getElementById('dos-s').innerHTML = '41cms'
          document.getElementById('tres-s').innerHTML = '39cms'
          document.getElementById('cuatro-s').innerHTML = '13cms'
        }else if(size == 2){
          document.getElementById('uno-s').innerHTML = '59cms'
          document.getElementById('dos-s').innerHTML = '42cms'
          document.getElementById('tres-s').innerHTML = '41cms'
          document.getElementById('cuatro-s').innerHTML = '15cms'
        }else if(size == 3){
          document.getElementById('uno-s').innerHTML = '62cms'
          document.getElementById('dos-s').innerHTML = '45cms'
          document.getElementById('tres-s').innerHTML = '43cms'
          document.getElementById('cuatro-s').innerHTML = '17cms'
        }else if(size == 4){
          document.getElementById('uno-s').innerHTML = '64cms'
          document.getElementById('dos-s').innerHTML = '49cms'
          document.getElementById('tres-s').innerHTML = '45cms'
          document.getElementById('cuatro-s').innerHTML = '19cms'
        }else if(size == 5){
          document.getElementById('uno-s').innerHTML = '66cms'
          document.getElementById('dos-s').innerHTML = '51cms'
          document.getElementById('tres-s').innerHTML = '47cms'
          document.getElementById('cuatro-s').innerHTML = '20cms'
        }
        else if(size == 6){
          document.getElementById('uno-s').innerHTML = 'cms'
          document.getElementById('dos-s').innerHTML = 'cms'
          document.getElementById('tres-s').innerHTML = 'cms'
          document.getElementById('cuatro-s').innerHTML = 'cms'
        }
      },


    },
  };
</script>

<style scoped>
.modal2-body {
    position: relative;
    height: 61vh;
    padding: 1vw 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}.genre-tallas {
    display: flex;
}.tallas-cont {
    display: flex;
    width: 35vw;
    justify-content: space-between;
    margin-bottom: 2vw;
}.modal2-header {
    position: revert;
    /* border-bottom: 1px solid #eeeeee; */
}button.btn-close {
    top: 2.7vw;
    right: 7.5vw;
    z-index: 10000;
    border-radius: 2vw;
    position: absolute;
    background:#b70d1d;
    line-height: 1vw!important;
    font-size: 1.7vw;
}.genre-name{
  cursor: pointer;
}.camiseta-talla-cont{
    height: 26vw;
    width: 36.458vw;
}.camiseta-talla-cont.adult-ninio {
  background-image: url('../assets/adulto-talla.png');
  background-size: 31.719vw 25.365vw;
    background-repeat: no-repeat;
    background-position-x: center;
}.camiseta-talla-cont.dama {
  background-image: url('../assets/dama-talla.png');
  background-size: 31.719vw 25.365vw;
    background-repeat: no-repeat;
    background-position-x: center;
}.modal2 {
    height: 80vh;
    justify-content: center;
}.genre-tallas {
    width: 24vw;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2vw;
}

.genre-t-cont{
  flex-direction: column;
  align-items: center;
    width: 100%;
}.talla-name{
  cursor: pointer;
}

#adult-cont{
  display: flex;
}#ninio-cont{
  display: none;
}#dama-cont{
  display: none;
}


h5.cms{
    width: fit-content;
    font-size: 1.250vw;
    line-height: 1.458vw;
    color: #B70D1D;
    font-family: 'Rajdhani';
}h5.cms.largo {
    position: relative;
    top: 13vw;
}h5.cms.busto {
    position: relative;
    top: 8vw;
    left: 16vw;
}h5.cms.cintura {
    position: relative;
    top: 11vw;
    left: 16vw;
}h5.cms.hombro {
    position: relative;
    left: 31vw;
    top: -3.5vw;
    transform: rotate(62deg);
}

h1.titulo-tallas {
    font-size: 4.167vw;
    line-height: 4.792vw;
    color: #032A4B;
    font-family: 'Rajdhani';
}h3.subt-tallas {
    font-size: 1.354vw;
    line-height: 1.563vw;
    color: #A0A0A0;
    margin-bottom: 2vw;
}h3.genre-name {
    font-size: 1.302vw;
    line-height: 1.510vw;
    color: #CBCBCB;
    font-family: 'Rajdhani';
    text-decoration-color: white;
    transition: .5s;
}h3.genre-name:hover{
    text-decoration-line: underline;
    text-decoration-color: #CBCBCB;
}.act{
    text-decoration-line: underline!important;
    text-decoration-color: crimson!important;
    color:crimson!important;
}.act-road,.act-road2,.act-road3 {
    background: #B70D1D;
    border: none;
    color: white;
}.dim.uno h5.cms.hombro {
    position: relative;
    left: 31vw;
    top: -2vw;
    transform: rotate(
62deg);
}.talla-name {
    font-size: 1.302vw;
    line-height: 1.510vw;
    font-family: 'Rajdhani';
    color: #cbcbcb;
    border: 1px solid #cbcbcb;
    width: 4.427vw;
    height: 4.427vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4.427vw;
}.talla-body-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media (max-width:1440px) {
 /* .talla-body-cont {
    transform: scale(1.2);
}*/
}
@media (max-width:768px) {
  .talla-body-cont {
    transform: scale(1.9);
    margin-top: 18vw;
}#dama-cont .dim {
    position: relative;
    top: -2vw;
}#ninio-cont .dim,#adult-cont .dim {
    position: relative;
    top: -1vw;
}button.btn-close {
    top: 11.7vw;
    right: 7.5vw;
    z-index: 10000;
    border-radius: 6vw;
    position: absolute;
    background: #b70d1d;
    line-height: 1vw !important;
    font-size: 3.7vw;
}
}
</style>