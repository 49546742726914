<template>
  <div id="sec_product_detail">
    <HeaderHome :name="user.fullname" />

    <!-- <h1>Detalles de compra</h1> -->

    <div class="product_item">
      
      <div class="product-cont-sl">
        <div class="product_image">
          <vueper-slides
          class="no-shadow"
          ref="vueperslides1"
          @slide="$refs.vueperslides2 && $refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })"
          :slide-ratio="1 / 4"
          :arrows="false">
          <vueper-slide
              class="pro-img"
              v-for="(sl,i) in data.imagenes"
              :key="i"
              :image="urlpath.url() + '/get-product-image/' + sl.image"/>
          </vueper-slides>
        </div>

        <div
          class="product_imgs"
          v-if="data.imagenes != 'No se encontraron coincidencias'"
        >
          <vueper-slides
            class="no-shadow"
            ref="vueperslides2"
            :slide-ratio="1 / 8"
            :dragging-distance="50"
            @slide="$refs.vueperslides1 && $refs.vueperslides1.goToSlide($event.currentSlide.index, {emit: false})"
            :visible-slides="3"
            :touchable="false"
          >
          <vueper-slide
            v-for="(sl,i) in data.imagenes"
            :key="i"
            @click.native="$refs.vueperslides2 && $refs.vueperslides2.goToSlide(i)">
              <template #content>
                <img :src="urlpath.url() + '/get-product-image/' + sl.image" alt="img" class="product-cont-img">
              </template>
            </vueper-slide>
          </vueper-slides>
        </div>
      </div>

      <div class="product_galery">
       
      </div>




      <TallaModal v-show="isModalVisibleTalla" @close="closeModalTalla">
        <template v-slot:header>  </template>

        <template v-slot:body> 
          
        </template>
      </TallaModal>

      <CarDirModal v-show="isModalVisibleDir" @close="closeModalDir" >
        <template v-slot:header>  </template>

        <template v-slot:body> 
            <div class="talla-body-cont">
              <div class="F1">
                  <img src="../../assets/Vector.png">
              </div>
              <div class="F2">
                  <p>´{{data.name}}´ se ha agregado a tu carrito</p>
              </div>
              <div class="F3">
                <a href="/carrito">
                <div class="F3_C1">                    
                    <p>Ver Carrito</p>
                </div>
                </a>
                <a href="/tienda">
                <div class="F3_C2">
                    <img src="../../assets/Arrow_55.png">
                    <p>Regresar a Tienda</p>
                </div>
                </a>
              </div>
          </div>
        </template>
      </CarDirModal>


      <div class="product_info">
        <h1>{{ data.name }}</h1>
        <h4>{{ data.descrp }}</h4>
        <div class="buttons-product">
          <div class="disponibles">
            <p>Disponibles: {{ this.totalStock() }} piezas</p>
          </div>
          <div v-if="data.total_stock > 0" class="product_data">
            <div class="talla">
              <p>Talla:</p>
              <a @click="showModalTalla(data)">Guía de tallas</a>
            </div>
            <select id="select-size" v-model="talla">
              <option
                :disabled="talla.encontrado == 'no'"
                v-for="(talla, key) in findSizes()"
                v-bind:key="key"
                v-bind:value="talla.name"
              >
                {{ talla.name }}
              </option>
            </select>
            <div class="color">
              <p>Color:</p>
            </div>
            <select id="select-size" v-model="color">
              <option
                :disabled="color.encontrado == 'no'"
                v-for="(color, key) in findColors()"
                v-bind:key="key"
                v-bind:value="color.name"
              >
                {{ color.name }}
              </option>
            </select>

            <p class="restablecer" @click="refresh()">Restablecer</p>
            <!-- &#8634; -->
            <div class="options_prod">
             <div class="cantidad_prod">
                <p>Cantidad</p>
                <div class="buttons_cant">
                   <button
                    :disabled="
                      this.color == '' || this.talla == '' || this.stock == 0
                    "
                    @click="res(stock)"
                  >
                    &#45;
                  </button>
                 
                  <p>{{ cantidad }}</p>
                  <button
                    :disabled="
                      this.color == '' || this.talla == '' || this.stock == 0
                    "
                    @click="sum(stock)"
                  >
                    &#43;
                  </button>
                </div>
              </div>

              <div class="total_prod">
                <p>Total</p>
                <h5>${{ this.total() }}</h5>
              <!--  <a href="">Ver detalles de envio</a> -->
              </div>
            </div>
            

            <button v-if="data.status !='INACTIVO'"
              class="añadir_carrito"
              :class="{
                dis_btn:
                  this.color == '' || this.talla == '' || this.stock == 0,
              }"
              :disabled="
                this.color == '' || this.talla == '' || this.stock == 0
              "
              @click="addToCart()"
            >
              AGREGAR AL CARRITO
              <img src="../../assets/Arrow_6.png" alt="imagen flecha" />
            </button> 
             <div v-else>
              
            <p class="msg_nostock">Producto actualmente no disponible.</p>
   
             </div>
            
          <!--  <a  class="towasap" href="https://api.whatsapp.com/send?phone=526624167033&text=%C2%A1Hola%20Cimarrones!%20Me%20interesa%20comprar%20este%20producto%20de%20su%20tienda.%20" target="_blank">
              Comprar por WhatsApp
             <img src="../../assets/Arrow_6.png" alt="imagen flecha" />
            </a>
  -->

            <div v-if="this.status != ''">
             <div v-if="this.status == 'success'">
                <p class="cart_msg cart_success">
                  {{ this.msg }}
                  <span class="btn_toCart" @click="toCart()">Ver carrito</span>
                </p>
              </div>
              <div v-if="this.status == 'error'">
                <p class="cart_msg cart_error">{{ this.msg }}</p>
              </div>
            </div>
          </div>
          <div v-else class="product_data">
            <p class="msg_nostock">No hay stock para este producto.</p>
          </div>
        </div>
      </div>
    </div>

    

    <Footer />
  </div>
</template>
<script>
import IconSuccess from "../../assets/Vector.png";
import cartService from "../../services/cart.service";
import Footer from "../../components/footer";
import HeaderHome from "../../components/HeaderHome";
import TallaModal from "../../components/TallaModal";
import AnuncioBar from "../../components/AnuncioBar";
import CarDirModal from "../../components/CarDirModal";
import { mapActions } from "vuex";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

let urlpath = require("../../global/url");
export default {
  name: "ProductDetail",
  components: {
    HeaderHome,
    Footer,
    VueperSlides,
    VueperSlide,
    TallaModal,
    CarDirModal
  },
  data() {
    return {
      status: "",
      msg: "",
      cantidad: 1,
      talla: "",
      subtotal: 0,
      color: "",
      stock: 0,
      updatedStock: 0,
      urlpath,
      isModalVisibleTalla: false,
      isModalVisibleDir: false,
      modalDataTalla: null,
    };
  },
  async beforeMount() {
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
  },
  async created() {
    let name = this.$route.params.name;
    let payload = {
      title: name,
      option: "product/url",
    };
   await this.findByTitle(payload);

    this.getColors("Colores");
    this.getSizes("Tallas");

    if(this.data.status =='INACTIVO'){
      this.$router.push("/tienda").catch((err) => {});
    }
  
  },
  updated() {
    if (this.stock != this.updatedStock) {
      this.updatedStock = this.stock;
      this.cantidad = 1;
     /* this.status = "";
      this.msg = ""; */
    }
  },
  

  computed: {
    data() {
      return this.$store.getters["product/data"];
    },

    url() {
      return this.$store.getters["main/baseURL"];
    },
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
    allColors() {
      return this.$store.getters["color/data"];
    },
    allSizes() {
      return this.$store.getters["size/data"];
    },
  },
  methods: {
    ...mapActions("color", ["getColors"]),
    ...mapActions("size", ["getSizes"]),
    ...mapActions("product", ["findByTitle"]),
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    wait2: function () {
      setTimeout(() => this.$router.go(), 1000);
    },

    showModalTalla(data) {
      this.isModalVisibleTalla = true;
      this.modalDataTalla = data;
    },
    showModalDir() {
      this.isModalVisibleDir = true;
    },
    closeModalTalla() {
      this.isModalVisibleTalla = false;
      this.modalDataTalla = null;
    },
    closeModalDir() {
      this.isModalVisibleDir = false;
    },
    refresh: function () {
      /* this.status="";
       this.msg = "";
       this.cantidad=1
        this.talla =''
        this.color=''
        this.subtotal=0,
        this.stock=0,
        this.updatedStock=0*/
      setTimeout(() => this.$router.go());
    },
    salir: function () {
      this.$router.push("/").catch((err) => {});
    },
    showModal() {
      this.$refs["modal-editar"].show();
    },
    toCart: function () {
      this.$router.push("/carrito").catch((err) => {});
    },

    delStatus: function () {
      setTimeout(() => this.delMsgs()
      ,3000);
    
    },
    delMsgs: function(){
       this.status=""
       this.msg=""
    },
    // Carrito. --------------------------------------------------------------------------------------------------------

    addToCart: async function () {
      window.fbq('track', 'AddToCart');
      this.status = "";
      this.msg = "";

      let id_stock, id_color, id_size;
      let stock = this.data.stock;
      for (var i = 0; i < stock.length; i++) {
        if (
          stock[i].color_name == this.color &&
          stock[i].size_name == this.talla
        ) {
          id_stock = stock[i].id;
          id_color = stock[i].id_color;
          id_size = stock[i].id_size;
        }
      }

      let code = cartService.generateCode();

      let itemCode = this.data.code + "-" + code;

      let item = {
        nombre: this.data.name,
        descripcion: this.data.descrp,
        talla: this.talla,
        color: this.color,
        cantidad: this.cantidad,
        subtotal: this.subtotal,
        codigo: itemCode,
        disponibles: this.stock,
        image: this.data.image,
        precio: this.data.price,
        extra: {
          id_prod: this.data.id,
          id_stock: id_stock,
          id_size: id_size,
          id_color: id_color,
        },
      };

      let result = await this.$store.dispatch("cart/AddItemToCart", item);

      if (result.status != "error") {
        this.status = "success";
        this.msg = result.message;
        this.isModalVisibleDir = true;
        this.cantidad = 1;
        this.talla = "";
        this.color = "";
        (this.subtotal = 0), (this.stock = 0), (this.updatedStock = 0);
         this.delStatus()
        // this.wait2()
      } else {
        this.status = "error";
        this.msg = result.message;
      }

      // this.refresh()
    },

    // Filtrar colores y tallas para encontrar las que si estan disponibles en el producto.

    findColors: function () {
      let result = [];

      let colores = this.allColors;
      let prod = this.data.stock; //obtenemos el stock para buscar los colores que si tiene el producto.

      for (var i = 0; i < colores.length; i++) {
        let color = colores[i].name;
        let encontrado = "no";
        for (var a = 0; a < prod.length; a++) {
          if (prod[a].color_name == color) {
            encontrado = "si";
          }
        }

        let item = { name: color, encontrado: encontrado };

        result.push(item);
      }
     
     /*
      if (this.talla != "") {
        // se ha elejido una  talla entonces se devuelven solo los colores que estan disponibles en esa talla

        let result = [];

        for (var i = 0; i < colores.length; i++) {
          let color = colores[i].name;
          let encontrado = "no";
          for (var a = 0; a < prod.length; a++) {
            if (
              prod[a].color_name == color &&
              prod[a].size_name == this.talla
            ) {
              encontrado = "si";
            }
          }

          let item = { name: color, encontrado: encontrado };

          result.push(item);
        }

       let reorder =[]
      for(var k=0; k<result.length; k++){
          if(result[k].encontrado=='si'){
            reorder.push(result[k])
          }
      }
      return reorder
      }*/

      let reorder =[]
      for(var k=0; k<result.length; k++){
          if(result[k].encontrado=='si'){
            reorder.push(result[k])
          }
      }

       if(this.color==''){
        this.color = reorder[0].name //enviar la primera talla como default
      }

      return reorder
    },

    findSizes: function () {
      let result = [];

      let tallas = this.allSizes;
      let prod = this.data.stock; //obtenemos el stock para buscar las tallas que si tiene el producto.

      for (var i = 0; i < tallas.length; i++) {
        let talla = tallas[i].name;
        let encontrado = "no";
        for (var a = 0; a < prod.length; a++) {
          if (prod[a].size_name == talla) {
            encontrado = "si";
          }
        }

        let item = { name: talla, encontrado: encontrado };

        result.push(item);
      }

      /*
      if (this.color != "") {
        // se ha elejido un color entonces se devuelven solo las tallas que estan disponibles en ese color

        let result = [];

        for (var i = 0; i < tallas.length; i++) {
          let talla = tallas[i].name;
          let encontrado = "no";
          for (var a = 0; a < prod.length; a++) {
            if (
              prod[a].size_name == talla &&
              prod[a].color_name == this.color
            ) {
              encontrado = "si";
            }
          }

          let item = { name: talla, encontrado: encontrado };

          result.push(item);
        }

         let reorder =[]
      for(var k=0; k<result.length; k++){
          if(result[k].encontrado=='si'){
            reorder.push(result[k])
          }
      }
      return reorder
      } */

      
      let reorder =[]
      for(var k=0; k<result.length; k++){
          if(result[k].encontrado=='si'){
            reorder.push(result[k])
          }
      }
      if(this.talla==''){
        this.talla = reorder[0].name //enviar la primera talla como default
      }
      
      return reorder
      //return result;
    },

    // Metodos del producto. --------------------------------------------------------------------------------------------
    total: function () {
      let result = this.data.price * this.cantidad;
      this.subtotal = result;
      result = Math.round(result * 100) / 100;
      return result;
    },
    sum: function (stock) {
      if (this.cantidad < stock) {
        this.cantidad = this.cantidad + 1;
      }
    },
    res: function () {
      if (this.cantidad > 1) {
        this.cantidad = this.cantidad - 1;
      }
    },

    totalStock: function () {
      let result = 0;
      if (this.color != "" && this.talla != "") {
        for (var i = 0; i < this.data.stock.length; i++) {
          if (
            this.data.stock[i].color_name == this.color &&
            this.data.stock[i].size_name == this.talla
          ) {
            result = result + this.data.stock[i].stock;
          }
        }
      }
      this.stock = result;

      return result;
    },
  },


  /**titulo pagina */
  watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                let titleM = this.$route.params.title
                let title = titleM.replaceAll('-',' ')
                document.title = to.meta.title || 'Producto '+title+' - Cimarrones de Sonora';
            }
        },
    }
};
</script>

<style scoped>
.talla a:hover{
  color: #968220 !important;
  cursor: pointer;
}

.elipses {
  display: none;
}
.product_item {
  display: flex;
  margin-top: 5.989583333333334vw;
  margin-left: 12.239583333333332vw;
  margin-bottom: 5.989583333333334vw;
}

.product_image {
  background: #ffffff;
  border: 0.052083333333333336vw solid #032a4b;
  box-sizing: border-box;
  border-radius: 1.3020833333333335vw;
  padding: 2vw;
}

.product_image .vueperslides__parallax-wrapper{
    height: 24.895833333333332vw!important;
}

.product_image .product-cont-img {
    width: 5.3125vw;
    height: 5.3125vw;
    cursor: pointer;
}

.product_image img {
  width: 23.28125vw;
  height: 24.895833333333332vw;
}

.product_image .pro-img {
  width: 30.28125vw;
  height: 23.895833vw;
  background-size: 25vw;
  background-repeat: no-repeat;
}

.product_imgs {
  margin-top: 2.7083333333333335vw;
  width: 33.697916666666664vw;
  padding-left: 5.489583vw;
}
.product_imgs img {
  width: 5.3125vw;
  height: 5.3125vw;
  margin-right: 3.4895833333333335vw;
  margin-bottom: 1vw;
}

.product_info {
  margin-left: 5.572916666666667vw;
}

.product_info h1 {
  font-size: 3.125vw;
  font-family: "Rajdhani";
  color: #032a4b;
}
.product_info h4 {
  font-size: 1.52083vw;
  font-family: "Rajdhani";
  color: #032a4b;
}

.disponibles p {
  font-size: 0.8333333333333334vw;
  font-family: "Rajdhani";
  color: #686868;
}

.product_data {
  margin-top: 3.5937499999999996vw;
  width: 20.833333333333336vw;
}

.talla {
  display: flex;
  justify-content: space-between;
}
.talla p,
.talla a,
p {
  font-size: 0.8333333333333334vw;
  color: #032a4b;
  font-weight: 600;
  font-family: "Rajdhani";
}/*
.talla p {
  margin-right: 14.6vw;
}*/
.talla a {
  text-decoration: underline;
}

.product_data select {
  border-radius: 0vw;
  width: 20.833333333333336vw;
  height: 3.6291666666666673vw;
  margin-bottom: 1.475vw;
  font-family: "Rajdhani"!important;
  color: #032a4b;
  font-size: 0.952083333333333336vw;
  padding-left: 1vw;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../../assets/flecha-blue.png');
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 19vw;
    background-size: 0.6vw;
}
.product_data select:focus-visible {
  border-radius: 0vw !important;
}
.product_data select option {
  font-size: 0.8333333333333334vw;
  color: #032a4b;
  font-family: "Rajdhani"!important;
  padding: 0.052083333333333336vw;
}

.options_prod {
  display: flex;
  margin-bottom: 2.864583333333333vw;
}

.buttons_cant {
  display: flex;
}

.cantidad_prod {
  width: 169px;
  margin-right: 1.875vw;
}
.buttons_cant {
  border: 0.052083333333333336vw solid #032a4b;
  width: 8.802083333333334vw;
  height: 3.646vw;
  padding-top: 1.1020833333333335vw;
  padding-left: 1.4583333333333333vw;
  padding-bottom: 0.9895833333333333vw;
}
.buttons_cant p {
  width: 2.541667vw;
  text-align: center;
  margin-bottom: 0vw;
  padding-top: 0.1vw;
  padding-bottom: 0vw;
  font-size: 1.0416666666666665vw;
  color: #032a4b;
  font-family: "Rajdhani";
}
.buttons_cant button {
  width: 1.5416666666666665vw;
  font-size: 1.0416666666666665vw;
  color: #032a4b;
  font-family: "Rajdhani";
  border: 0vw;
}

button.añadir_carrito {
  border-radius: 0vw;
  border: 0vw;
  background: #b70d1d;
  color: #ffffff;
  width: 20.833333333333336vw;
  height: 3.6458333333333335vw;
  font-size: 0.78125vw;
  font-family: "Rajdhani";
  text-align: left;
  padding-left: 7.2916666666666665vw;
  transition: .5s;
}

button.añadir_carrito:hover{
  padding-left: 2.2916666666666665vw;
}



a.towasap {
  cursor: pointer;
  border-radius: 0vw;
  border: 0vw;
  background: #b70d1d;
  color: #ffffff;
  width: 20.833333333333336vw !important;
  height: 3.6458333333333335vw !important;
  font-size: 0.78125vw;
  font-family: "Rajdhani";
  text-align: left;
 /* padding-left: 7.2916666666666665vw;
  transition: .5s;
  */
      padding: 1vw 2vw;
  
}

a.towasap img{
  padding-left: .5vw;
}
/*
a.towasap:hover{
  padding-left: 2.2916666666666665vw;
}
*/

p.msg_nostock {
  border-radius: 0vw;
  border: 0vw;
  background: #968220;
  color: #ffffff;
  padding: 1.6458333333333335vw 2.833333333333336vw;

  font-size: 0.78125vw;
  font-family: "Rajdhani";
  text-align: left;
  padding-left: 2.2916666666666665vw;
}

button.añadir_carrito img {
  width: 0;
  margin-left: 0;
  transition: .5s;
}
/*button.añadir_carrito:hover img {
  width: 2.8125vw;
  margin-left: 6.760417vw;
}*/

.total_prod h5 {
  color: #968220;
  font-family: "Rajdhani";
  font-size: 1.9791666666666665vw;
}
.total_prod a {
  color: #8293a1;
  font-size: 0.7291666666666666vw;
  text-decoration: underline;
  font-family: "Rajdhani";
}
p.restablecer {
  text-align: right;
  color: #032a4b;
  font-size: 0.8333333333333334vw;
  font-family: "Rajdhani";
  transition: all 300ms;
  cursor: pointer;
    width: fit-content;
}
p.restablecer:hover {
  color: #968220;
}

.dis_btn {
  background: #686868 !important;
}

/*modal */
.modal2-header {
    position: revert;
    /* border-bottom: 1px solid #eeeeee; */
}button.btn-close {
    top: 5.5vw;
    right: 7.5vw;
    z-index: 10000;
    border-radius: 2vw;
    position: absolute;
}



/** MODAL */

.ButtonClose{
  width: 2.1354166666666665VW;
  height: 2.1354166666666665VW;
  font-size: 0.78125VW;
  font-weight: 800;
  border: 0;
  border-radius: 50%;
  color: white;
  background-color: #B70D1D;
  margin-left: 29vw;
  margin-top: -0.7vw;
  position: fixed;
}

.modal2 {
    height: 15.208333333333332VW;
    width: 30.312499999999996VW;
    justify-content: center;
}

.F1{
    text-align: center;
    padding-top: 2.5VW;
}
    .F1 img{
        width: 2.2395833333333335VW;
        height: auto;
    }

.F2{    
    width: 26vw;
    height: 1vw;
    text-align: center;
    padding-top: 1.6VW;
    padding-bottom: 2.3vw;
}
    .F2 p{        
        font-size: 0.7291666666666666VW;
        color: var(--color-8);
        font-family: 'Rajdhani';
        font-weight: 700;
    }

.F3{
    display: flex;
    width: 26vw;
    padding-left: 4.3vw;
}
    .F3_C1{        
        width: 7.395833333333333VW;
        height: 2.2395833333333335VW;
        border: 1px solid var(--color-8);
        text-align: center;
        padding-top: 0.6vw;
        margin: 0vw;
        cursor: pointer;
        margin-right: 0.4vw;
    }
        .F3_C1 p{
            font-size: 0.5729166666666666VW;
            color: var(--color-8);
            font-family: 'Rajdhani';
        }
    .F3_C2{
        display: flex;
        width: 9.6875VW;
        height: 2.2395833333333335VW;
        background: var(--color-8);
        border: 1px solid var(--color-8);
        align-items: center;
        justify-content: space-between;
        padding: 0vw 0.7vw;
        cursor: pointer;
    }
    .F3_C2 p{
        font-size: 0.5729166666666666VW;
        color: var(--color-4);
        font-family: 'Rajdhani';        
        white-space: nowrap;
    padding-top: 1.1vw;
    }
    .F3_C2 img{
        width: 1.1979166666666667VW;
        height: 0.5vw;
    }

@media (max-width: 720px) {
  .product_item {
    display: block;
    margin-left: 9.420289855072465vw;
    margin-top: 10.38647342995169vw;
  }

  .product_galery {
    width: 81.88405797101449vw;
  }
  .product_imgs {
    display: none;
  }
.product_image .vueperslides {
    position: relative;
    width: 69.971014vw;
}
  .product_image {
    background: #ffffff;
    border: 0.24154589371980675vw solid #032a4b;
    box-sizing: border-box;
    border-radius: 6.038647342995169vw;
    padding: 6vw;
    width: 80.971014vw;
  }

  .cantidad_prod {
  width: 169px;
  margin-right: 26.875vw;
}

  .product_image img {
    width: 57.971014492753625vw;
    height: 62.07729468599034vw;
  }

  .product_image .vueperslides__parallax-wrapper {
    height: 62.07729468599034vw;
}

  .product_image .pro-img {
    width: 69.971014vw;
    height: 62.07729468599034vw;
    background-size: 70vw;
}
  
  .elipses {
    display: flex;
    margin-top: 6.280193236714976vw;
    margin-bottom: 11.594202898550725vw;
    margin-left: 36vw;
  }

  .elipse {
    width: 2.1739130434782608vw;
    height: 2.1739130434782608vw;

    margin-right: 2.4154589371980677vw;
  }

  .product_info {
    margin-left: 0vw;
  }

  .product_info h1 {
    font-size: 7.246376811594203vw;
  }
  .product_info h4 {
    font-size: 5.16376811594203vw;
    margin-bottom: 3.864734299516908vw;
  }

  .product_info p,
  .product_info a {
    font-size: 3.864734299516908vw;
  }

  .total_prod h5 {
    font-size: 9.178743961352657vw;
  }

  .disponibles {
    margin-bottom: 9.903381642512077vw;
  }.product_data {
    width: 81.833vw;
}
  .product_data select {
    width: 81.40096618357488vw;
    height: 16.908212560386474vw;
    font-size: 3.864734299516908vw;
    padding: 0.5vw 10vw;
    margin-bottom: 8.212560386473431vw;
    background-position-x: 69vw;
    background-size: 2.6vw;
  }

  button.añadir_carrito {
    width: 81.40096618357488vw;
    height: 16.908212560386474vw;
    font-size: 3.864734299516908vw;
    margin-bottom: 34.05797101449276vw;
    transition:.5s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  button.añadir_carrito img {
    width: 0;
  }

  button.añadir_carrito:hover img{
    margin-left: 2.5vw;
    width: 13.043478260869565vw!important;
  }

  .cart_msg {
    width: 81.40096618357488vw;
    height: 16.908212560386474vw;
    font-size: 3.864734299516908vw;
    color: #ffffff;
    font-family: "Rajdhani";
    margin-left: 9.420289855072465vw;
    padding: 2vw;
  }
/*
  .talla p {
    margin-right: 53vw;
  }*/
  p.restablecer {
    width: 81.40096618357488vw;
  }
  .product_data select option {
    font-size: 3.864734299516908vw;
    padding: 0.5;
  }

  .buttons_cant {
    width: 27.913043vw;
    height: 12vw;
    margin-bottom: 12.318840579710146vw;
  }

  .buttons_cant button {
    width: 8.541667vw;
    font-size: 3.864734299516908vw;
    padding: 0vw;
  }

  .buttons_cant p {
    width: 7.541667vw;
    padding-top: 2.5vw;
  }
}


@media (max-width: 768px){

  a.towasap {
 
    font-size: 4vw;
     padding: 3vw;
     margin-left: 12vw;
  
}

  
.ButtonClose{
  width: 9.903381642512077VW;
  height: 9.903381642512077VW;
  font-size: 3.6231884057971016VW;
  font-weight: 800;
  border: 0;
  border-radius: 50%;
  color: white;
  background-color: #B70D1D;
  margin-left: 70vw;
  margin-top: -5.7vw;
  position: fixed;
}

.modal2 {
    height: 84.54106280193237VW;
    width: 73.67149758454107VW;
    justify-content: center;
}

.F1{
    text-align: center;
    padding-top: 13.043478260869565VW;
}
    .F1 img{
        width: 10.38647342995169VW;
        height: auto;
    }

.F2{    
    width: 50.72463768115942VW;
    height: 14vw;
    margin-left: 9.5vw;
    padding-top: 1.6VW;
    padding-bottom: 2.3vw;
    margin-top: 5.903382VW;
    margin-bottom: 3.903382VW;
}
    .F2 p{        
        font-size: 3.3816425120772946VW;
        color: var(--color-8);
        font-family: 'Rajdhani';
        font-weight: 700;
    }

.F3{
    display: block;
    width: 49vw;
    margin-left: 9vw;
    padding-left: 4.3vw;
}
    .F3_C1{        
        width: 44.68599033816425VW;
        height: 10.38647342995169VW;
        border: 1px solid var(--color-8);
        text-align: center;
        padding-top: 3vw;
        margin: 0vw;
        cursor: pointer;
        margin-right: 0.4vw;
        margin-bottom: 2vw;
    }
        .F3_C1 p{
            font-size: 2.4154589371980677VW;
            color: var(--color-8);
            font-family: 'Rajdhani';
        }
    .F3_C2{
        display: flex;
        width: 44.68599033816425VW;
        height: 10.38647342995169VW;
        background: var(--color-8);
        border: 1px solid var(--color-8);
        align-items: center;
        justify-content: space-between;
        padding: 0vw 5vw;
        cursor: pointer;
    }
    .F3_C2 p{
        font-size: 2.4154589371980677VW;
        color: var(--color-4);
        font-family: 'Rajdhani';        
        white-space: nowrap;
        padding-top: 3vw;
    }
    .F3_C2 img{
        width: 3.1979166666666667VW;
        height: 0.5vw;
    }

}

</style>