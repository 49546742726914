<template>
  <transition name="modal-fade">
  <div class="modal2-backdrop" @click="close">
    <div class="modal2" @click.stop="">
      <header class="modal2-header">
        <button
          type="button"
          class="ButtonClose"
          @click="close"
        >
          x
        </button>
      </header>

      <section class="modal2-body">
        <slot name="body">
        
        </slot>
       </section>

  <!--    <footer class="modal2-footer">
        <slot name="footer">
        </slot>
      </footer>-->
    </div>
  </div>
  </transition>
</template>

<script>


export default {
    name: 'CarDirModal',
    
    methods: {
      close() {
        this.$emit('close');
      },
    }
}
</script>

<style scoped>

.ButtonClose{
  width: 2.1354166666666665VW;
  height: 2.1354166666666665VW;
  font-size: 0.78125VW;
  font-weight: 800;
  border: 0;
  border-radius: 50%;
  color: white;
  background-color: #B70D1D;
  margin-left: 29vw;
  margin-top: -0.7vw;
  position: fixed;
}

.modal2 {
    height: 15.208333333333332VW;
    width: 30.312499999999996VW;
    justify-content: center;
}

.F1{
    text-align: center;
    padding-top: 2.5VW;
}
    .F1 img{
        width: 2.2395833333333335VW;
        height: auto;
    }

.F2{    
    width: 26vw;
    height: 1vw;
    text-align: center;
    padding-top: 1.6VW;
    padding-bottom: 2.3vw;
}
    .F2 p{        
        font-size: 0.7291666666666666VW;
        color: var(--color-8);
        font-family: 'Rajdhani';
        font-weight: 700;
    }

.F3{
    display: flex;
    width: 26vw;
    padding-left: 4.3vw;
}
    .F3_C1{        
        width: 7.395833333333333VW;
        height: 2.2395833333333335VW;
        border: 1px solid var(--color-8);
        text-align: center;
        padding-top: 0.6vw;
        margin: 0vw;
        cursor: pointer;
        margin-right: 0.4vw;
    }
        .F3_C1 p{
            font-size: 0.5729166666666666VW;
            color: var(--color-8);
            font-family: 'Rajdhani';
        }
    .F3_C2{
        display: flex;
        width: 9.6875VW;
        height: 2.2395833333333335VW;
        background: var(--color-8);
        border: 1px solid var(--color-8);
        align-items: center;
        justify-content: space-between;
        padding: 0vw 0.7vw;
        cursor: pointer;
    }
    .F3_C2 p{
        font-size: 0.5729166666666666VW;
        color: var(--color-4);
        font-family: 'Rajdhani';        
        white-space: nowrap;
    padding-top: 1.1vw;
    }
    .F3_C2 img{
        width: 1.1979166666666667VW;
        height: 0.5vw;
    }

@media (max-width: 768px){
  
.ButtonClose{
  width: 9.903381642512077VW;
  height: 9.903381642512077VW;
  font-size: 3.6231884057971016VW;
  font-weight: 800;
  border: 0;
  border-radius: 50%;
  color: white;
  background-color: #B70D1D;
  margin-left: 70vw;
  margin-top: -5.7vw;
  position: fixed;
}

.modal2 {
    height: 84.54106280193237VW;
    width: 73.67149758454107VW;
    justify-content: center;
}

.F1{
    text-align: center;
    padding-top: 13.043478260869565VW;
}
    .F1 img{
        width: 10.38647342995169VW;
        height: auto;
    }

.F2{    
    width: 50.72463768115942VW;
    height: 14vw;
    margin-left: 9.5vw;
    padding-top: 1.6VW;
    padding-bottom: 2.3vw;
    margin-top: 5.903382VW;
    margin-bottom: 3.903382VW;
}
    .F2 p{        
        font-size: 3.3816425120772946VW;
        color: var(--color-8);
        font-family: 'Rajdhani';
        font-weight: 700;
    }

.F3{
    display: block;
    width: 49vw;
    margin-left: 9vw;
    padding-left: 4.3vw;
}
    .F3_C1{        
        width: 44.68599033816425VW;
        height: 10.38647342995169VW;
        border: 1px solid var(--color-8);
        text-align: center;
        padding-top: 3vw;
        margin: 0vw;
        cursor: pointer;
        margin-right: 0.4vw;
        margin-bottom: 2vw;
    }
        .F3_C1 p{
            font-size: 2.4154589371980677VW;
            color: var(--color-8);
            font-family: 'Rajdhani';
        }
    .F3_C2{
        display: flex;
        width: 44.68599033816425VW;
        height: 10.38647342995169VW;
        background: var(--color-8);
        border: 1px solid var(--color-8);
        align-items: center;
        justify-content: space-between;
        padding: 0vw 5vw;
        cursor: pointer;
    }
    .F3_C2 p{
        font-size: 2.4154589371980677VW;
        color: var(--color-4);
        font-family: 'Rajdhani';        
        white-space: nowrap;
        padding-top: 3vw;
    }
    .F3_C2 img{
        width: 3.1979166666666667VW;
        height: 0.5vw;
    }

}

</style>